<template>
  <div>
    <base-header type="gradient-success" class="pb-8 pt-5">
    </base-header>


    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">文章管理</h3>
                </div>
              </div>
            </div>
            <template>
              <form>
                <div class="row">
                  <div class="col-xl-3 col-lg-6">
                    <div class="form-group">
                      <base-input alternative="" label="文章标题">
                        <input v-model="form.title" ref="title" class="form-control"
                               placeholder="文章标题" @change="getPosts">
                      </base-input>
                    </div>
                  </div>
                  <div class="col-xl-3 col-lg-6">
                    <div class="form-group">
                      <base-input alternative="" label="分类">
                        <lone-select v-model="form.cate_id" :options="items.categoryList" hint="请选择分类" opkey="value"
                                     oplabel="text"/>
                      </base-input>
                    </div>
                  </div>
                  <div class="col-xl-3 col-lg-6">
                    <div class="form-group">
                      <base-input alternative="" label="状态">
                        <lone-select v-model="form.status" :options="items.statusList" hint="请选择状态" opkey="value"
                                     oplabel="text"/>
                      </base-input>
                    </div>
                  </div>
                  <div class="col-xl-3 col-lg-6">
                    <div class="form-group">
                      <base-input alternative="" label="发布时间">
                        <flat-picker slot-scope="{focus, blur}"
                                     @on-open="focus"
                                     @on-close="blur"
                                     :config="{allowInput: true}"
                                     class="form-control datepicker"
                                     v-model="form.date">
                        </flat-picker>
                      </base-input>
                    </div>
                  </div>
                </div>
              </form>
              <div class="table-responsive">
                <base-table thead-classes="thead-light"
                            :data="queryResult.list">
                  <template slot="columns">
                    <th>标题</th>
                    <th>作者</th>
                    <th>分类</th>
                    <th>标签</th>
                    <th>阅读量</th>
                    <th>状态</th>
                    <th>发布时间</th>
                    <th>操作</th>
                  </template>
                  <template slot-scope="{row}">
                    <td>
                      <router-link :to="{ name: 'post', params: {alias: row.alias, title: row.title} }">
                        {{ row.title }}
                      </router-link>
                    </td>
                    <td>
                      {{row.username}}
                    </td>
                    <td>
                      <badge type="info">{{row.cate_name}}</badge>
                    </td>
                    <td>
                      <span v-for="(tag, index) in row.tag_list" :key="index">
                       <badge type="primary">{{tag}}</badge> {{' '}}
                      </span>
                    </td>
                    <td>
                      {{row.views}}
                    </td>
                    <td>
                      {{ row.status | statusFilter }}
                    </td>
                    <td>
                      {{row.c_t | formatTime}}
                    </td>
                    <th scope="row">
                      <router-link :to="'/admin/editPost/'+row.alias" class="btn btn-success btn-sm">编辑</router-link>
                      <base-button size="sm" type="warning">隐藏</base-button>
                      <base-button size="sm" type="danger">删除</base-button>
                    </th>
                  </template>
                </base-table>
              </div>
              <base-pagination v-show="queryResult.total > 0" align="end" v-model="form.page_number" :value="form.page_number"
                     :total="queryResult.total" :perPage="form.page_size" size="sm"></base-pagination>
            </template>
          </card>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
  import flatPicker from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import {getAllCategory, postQueryList} from "../../api";
  import {Notify, getTimeMillFromDateStr, formatTime} from "../../utils";

  export default {
    components: {
      flatPicker
    },
    data() {
      return {
        items: {
          categoryList: [
            {value: null, text: '请选择分类'}
          ],
          statusList: [
            {value: null, text: '请选择状态'},
            {value: 0, text: '草稿'},
            {value: 1, text: '发布'},
            {value: 2, text: '隐藏'}
          ]
        },
        form: {
          title: null,
          cate_id: null,
          status: null,
          start_time: null,
          end_time: null,
          page_number: 1,
          page_size: 10,
          date: null
        },
        queryResult: {
          total: null,
          list: []
        }
      }
    },
    created() {
      this.initCategoryList();
      // this.form.date = getTodayDateStr();
      this.getPosts();
    },
    watch: {
      'form.cate_id'() {
        this.getPosts();
      },
      'form.status'() {
        this.getPosts();
      },
      'form.date'(val) {
        if (val != null) {
          this.form.start_time = getTimeMillFromDateStr(val);
          this.form.end_time = this.form.start_time + 86400000;
          this.getPosts();
        }
      },
      'form.page_number'(val) {
        if (val != null) {
          this.getPosts();
        }
      }
    },
    filters: {
      formatTime,
      statusFilter(val) {
        switch (val) {
          case 0:
            return "草稿";
          case 1:
            return "已发布";
          case 2:
            return "隐藏";
          default:
            return "";
        }
      }
    },
    methods: {
      initCategoryList() {
        getAllCategory().then(res => {
          if (res.data.code === 0) {
            let categoryList = res.data.data;
            categoryList.forEach(item => {
              this.items.categoryList.push({value: item.id, text: item.name});
            })
          } else {
            Notify.error(res.data.code + ": " + res.data.msg);
          }
        })
      },
      getPosts() {
        postQueryList(this.form).then(res => {
          if (res.data.code === 0) {
            this.queryResult.total = res.data.data.total;
            this.queryResult.list = res.data.data.items;
          } else {
            Notify.error(res.data.code + ": " + res.data.msg);
          }
        })
      }
    }
  };
</script>
<style scoped>
  /deep/ .lone-select {
    color: #8898aa;
    position: relative;
    width: 100%;
    text-align: left;
    outline: none;
    height: 40px;
    line-height: 38px;
    font-size: .875rem;
  }

  /deep/ .lone-select .selected {
    color: #8898aa;
  }

  /deep/ .lone-select .selected.focus {
    color: #8898aa;
    border: 1px solid #2dce89;
    border-radius: 4px;
  }


  /deep/ .lone-select .selected:after {
    position: absolute;
    content: "";
    top: 20px;
    right: 1em;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-color: #8898aa transparent transparent transparent;
  }

  /deep/ .lone-select .items {
    color: #8898aa;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #e4e7ed;
    position: absolute;
    background-color: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    left: 0;
    right: 0;
    z-index: 100;
  }

  /deep/ .lone-select .items div {
    color: #8898aa;
    padding-left: 1em;
    cursor: pointer;
    user-select: none;
  }

  /deep/ .lone-select .items div:hover {
    background-color: #f5f7fa;
  }

  /deep/ .selectHighlight {
    color: #2dce89;
    /* background-color: #409eff; */
    font-weight: 600;
  }
</style>
